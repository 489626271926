import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Logo from "../../images/Header/R_Logo.svg"
import SearchSVG from "../../images//Header/search.svg"
import LangDropdown from "../LangDropdown"
import { useWindowSize } from "@react-hook/window-size/throttled"
import Wrapper, { DropDown } from "./style"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import SidebarMenu from "../Sidebar"
import Headroom from "react-headroom"
import * as JsSearch from "js-search"
import { Link } from "gatsby-plugin-react-i18next"

import {
  Microram,
  Turboram,
  Rotoram,
  Modulram,
  Compactram,
  Electram,
  Anelar,
} from "./searchTerms"

// COMPONENT
const Header = ({ location, content, activeLanguage, hideBr }) => {
  const [isDarkened, setIsDarkened] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchValue, setSearch] = useState(null)
  const [width, height] = useWindowSize()

  const wrapperRef = useRef(null),
    headerWrapperRef = useRef(null),
    SearchButtonRef = useRef(null),
    CloseButtonRef = useRef(null),
    InputRef = useRef(null),
    colorPositionStart = useRef(null),
    colorPositionEnd = useRef(null),
    secondColorPositionStart = useRef(null),
    secondColorPositionEnd = useRef(null),
    introExists = useRef(null),
    formExists = useRef(null)

  let colorDiv = "#introduction"
  let secondColorDiv = "#form-contact"

  //GET SCROLL POSITION AND CHANGE COLOR
  useScrollPosition(
    ({ currPos }) => {
      let position = Math.abs(currPos.y)

      if (
        window.location.pathname.includes("about") ||
        window.location.pathname.includes("blog")
      ) {
        setIsDarkened(true)
      } else if (
        window.location.pathname.includes("contacts")
      ) {
        position <= secondColorPositionStart.current
          ? setIsDarkened(false)
          : position <=
            secondColorPositionStart.current + secondColorPositionEnd.current
          ? setIsDarkened(true)
          : setIsDarkened(false)
      } else {
        if (introExists.current === false || formExists.current === false) {
          setIsDarkened(false)
        } else {
          position <= colorPositionStart.current
            ? setIsDarkened(false)
            : position <= colorPositionStart.current + colorPositionEnd.current
            ? setIsDarkened(true)
            : position <= secondColorPositionStart.current
            ? setIsDarkened(false)
            : position <=
              secondColorPositionStart.current + secondColorPositionEnd.current
            ? setIsDarkened(true)
            : setIsDarkened(false)
        }
      }
    },
    [isDarkened]
  )
  // ---

  function onSetSidebarOpen(open) {
    setSidebarOpen(open)
  }

  useEffect(() => {
    let colorDivX = document.querySelector(colorDiv)
    let secondColorDivX = document.querySelector(secondColorDiv)

    colorDivX && (colorPositionStart.current = colorDivX.offsetTop)
    colorDivX && (colorPositionEnd.current = colorDivX.offsetHeight)
    secondColorDivX &&
      (secondColorPositionStart.current = secondColorDivX.offsetTop)
    secondColorDivX &&
      (secondColorPositionEnd.current = secondColorDivX.offsetHeight)

    colorDivX ? (introExists.current = true) : (introExists.current = false)
    secondColorDivX ? (formExists.current = true) : (formExists.current = false)
  }, [width, height, colorDiv, secondColorDiv])

  useEffect(() => {
    if (
      window.location.pathname.includes("about") ||
      window.location.pathname.includes("blog")
    ) {
      setIsDarkened(true)
    }
  }, [])

  useEffect(() => {
    sidebarOpen
      ? document.querySelector(".hamburguer-icon").classList.add("active")
      : document.querySelector(".hamburguer-icon").classList.remove("active")
  }, [sidebarOpen])

  useEffect(() => {
    let searchButton = SearchButtonRef.current
    let closeButton = CloseButtonRef.current
    let inputText = InputRef.current

    // SEARCH (https://github.com/bvaughn/js-search)
    const search = new JsSearch.Search("id")
    search.addIndex("title_" + activeLanguage)
    search.addIndex("tags_" + activeLanguage )
    search.addIndex("url")
    search.addDocuments([
      Microram,
      Turboram,
      Rotoram,
      Modulram,
      Compactram,
      Electram,
      Anelar,
    ])

    // Click do botão search
    searchButton.addEventListener("click", function() {
      if (
        !document.querySelector(".search").classList.contains("active-input")
      ) {
        document.querySelector(".search").classList.add("active-input")
        document.querySelector(".search-submit").classList.add("display-none")
        document.querySelector(".search-list").classList.remove("opacity")
        document.querySelector(".dropbtn").classList.add("opacity")
        document.querySelector(".close-search").classList.remove("display-none")
        setTimeout(function() {
          document.querySelector(".search-submit").type = "submit"
        }, 50)
        document.querySelector(".search-submit").disabled = true

        let links = document.querySelectorAll(".nav-link")

        for (let l = 0; l < links.length; l++) {
          links[l].classList.add("opacity")
        }
      }
    })

    // Check if input not empty
    inputText.addEventListener("keyup", function() {
      if (inputText.value === "") {
        document.querySelector(".search-submit").disabled = true
      } else {
        document.querySelector(".search-submit").disabled = false
      }
      setSearch({
        searchValue: search
          .search(inputText.value)
          .map(s => ({ 
              title: 
                activeLanguage === 'pt' || activeLanguage === 'br' ? 
                    s.title_pt :
                    activeLanguage === 'en' ?
                    s.title_en :
                    activeLanguage === 'es' ?
                    s.title_es :
                    activeLanguage === 'fr' &&
                    s.title_fr,
              url: s.url 
          })),
      })
    })

    closeButton.addEventListener("click", function() {
      document.querySelector(".close-search").classList.add("display-none")
      document.querySelector(".search-submit").classList.remove("display-none")
      document.querySelector(".search-list").classList.add("opacity")
      document.querySelector(".dropbtn").classList.remove("opacity")
      document.querySelector(".search").classList.remove("active-input")
      document.querySelector(".search-submit").type = "button"
      document.querySelector(".search-submit").disabled = false

      let links = document.querySelectorAll(".nav-link")
      for (let l = 0; l < links.length; l++) {
        links[l].classList.remove("opacity")
      }
    })
  }, [activeLanguage])

  return (
    <Wrapper
      isDarkened={isDarkened}
      location={location.pathname}
      sidebarLinksColor={sidebarOpen}
      id="header"
      ref={wrapperRef}
    >
      <Headroom
        disableInlineStyles
        style={{
          WebkitTransition: "all 0.3s ease-out",
          NozTransition: "all 0.3s ease-out",
          OTransition: "all 0.3s ease-out",
          transition: "all 0.3s ease-out",
        }}
        onUnpin={() => {
          document.querySelector("header").style.pointerEvents = "none"
        }}
        onPin={() => {
          document.querySelector("header").style.pointerEvents = "all"
        }}
      >
        <div className="header-wrapper" ref={headerWrapperRef}>
          <div className="logo-container">
            <Link alt="Mudar de Língua" to="/">
              <Logo />
            </Link>
          </div>
          <nav className="nav-primary">
            {activeLanguage === 'br' &&
                <a
                    className="nav-link nav-link-buy hvr-overline-from-center"
                    href="https://ram.massamadreblog.com.br/quero-comprar"
                    rel="noopener noreferrer"
                    target="_blank"
                    alt="Quero comprar"
                >
                    <div>Quero Comprar</div>
                </a>
            }
            {content['mainLinks'].map(x => (
              <Link
                key={x.name}
                to={x.url}
                alt={x.name}
                className="nav-link hvr-overline-from-center"
                id={x.url}
                partiallyActive
                activeClassName="active"
              >
                <div>{x.name}</div>
              </Link>
            ))}
            <DropDown>
              <button className="nav-link hvr-overline-from-center outros">
                {content['outros']['title']}
              </button>
              <div className="dropdown-content">
                {content['outros']['links'].map(y => (
                    y.outbound === false ?
                        <Link
                            key={y.name}
                            to={y.url}
                            alt={y.name}
                            className="nav-link hvr-overline-from-center nav-outros"
                            id={y.url}
                            partiallyActive
                            activeClassName="active"
                        >
                            <div>{y.name}</div>
                        </Link>
                    :
                        <a
                            key={y.name}
                            href={y.url}
                            alt={y.name}
                            className="nav-link hvr-overline-from-center nav-outros"
                            id={y.url}
                            partiallyActive
                            activeClassName="active"
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div>{y.name}</div>
                        </a>
                ))}
              </div>
            </DropDown>
            <nav className="nav-secondary">
              <LangDropdown
                activeLanguage={activeLanguage}
                location={location}
                isDarkened={isDarkened}
                hideBr={hideBr}
              />
              <div className="search-container">
                <form action="" autoComplete="on">
                  <input
                    className="search"
                    list="search-keywords"
                    name="search"
                    placeholder="Introduza o seu termo de pesquisa..."
                    autoComplete="off"
                    ref={InputRef}
                  />
                  <ul className="search-list">
                    {searchValue !== null &&
                      searchValue.searchValue.map(ele => (
                        <li key={ele.title} value={ele.title}>
                          <Link to={`/${ele.url}`}>{ele.title}</Link>
                        </li>
                      ))}
                  </ul>
                  <button
                    type="button"
                    className="search-submit"
                    value="search-submit"
                    ref={SearchButtonRef}
                    aria-label="Search"
                  >
                    <SearchSVG />
                  </button>
                </form>
              </div>
              <button
                type="button"
                className="close-search display-none"
                ref={CloseButtonRef}
              >
                <div></div>
              </button>
              <SidebarMenu
                isOpen={sidebarOpen}
                setOpen={setSidebarOpen}
                onSetOpen={onSetSidebarOpen}
                content={content['sidebar']}
                contentMsg={content['msgMarkPhone']}
                activeLanguage={activeLanguage}
              />
              <button
                className="btn-menu"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <div className="hamburguer-icon">
                  <div></div>
                </div>
              </button>
            </nav>
          </nav>
        </div>
      </Headroom>
    </Wrapper>
  )
}

// PROP-TYPES
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
