import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Link } from "gatsby"

import { font, color, ease, device } from "../../layout/global"

import LogoR from "../../images/Footer/logo_r.svg"
import LogoSmall from "../../images/Header/small-logo.svg"
import LogoFacebook from "../../images/Footer/logo_facebook.svg"
import LogoInstagram from "../../images/Footer/logo_instagram.svg"
import LogoLinkedIn from "../../images/Footer/logo_linkedin.svg"
import LogoYoutube from "../../images/Footer/logo_youtube.svg"

const Footer = ({ lang, text1, text2, text3, text4, text5, text6, denuncias }) => {
  var link_insta = "https://www.instagram.com/ramalhos.en/"
  var link_facebook = "https://www.facebook.com/ramalhos.headquarters"

  if (lang === "es") {
    link_insta = "https://www.instagram.com/ramalhos.es/"
  }
  if (lang === "fr") {
    link_insta = "https://www.instagram.com/ramalhos.fr/"
    link_facebook = "https://www.facebook.com/search/top?q=ramalhos%20france"
  }
  if (lang === "mx") {
    link_insta = "https://www.instagram.com/ramalhos.mx/"
    link_facebook = "https://www.facebook.com/ramalhos.mx"
  }

  const botaoRef = useRef(null)
  const grupoRef = useRef(null)
  const d1Ref = useRef(null)
  const d2Ref = useRef(null)
  const d3Ref = useRef(null)
  const d4Ref = useRef(null)

  useEffect(() => {
    let btnRef = botaoRef.current
    let grpRef = grupoRef.current
    let d1 = d1Ref.current
    let d2 = d2Ref.current
    let d3 = d3Ref.current
    let d4 = d4Ref.current

    btnRef.addEventListener("click", function () {
      if (grpRef.style.display === "block") {
        setTimeout(function () {
          grpRef.style.display = "none"
        }, 200)

        setTimeout(function () {
          d1.classList.remove("active")
          d2.classList.remove("active")
          d3.classList.remove("active")
          d4.classList.remove("active")
        }, 50)
      } else {
        grpRef.style.display = "block"
        setTimeout(function () {
          d1.classList.add("active")
          d2.classList.add("active")
          d3.classList.add("active")
          d4.classList.add("active")
        }, 50)
      }
    })
  }, [])

  const breakpoints = useBreakpoint()
  return (
    <Wrapper>
      <div className="footer-container">
        {/* <div></div> */}
        <div>
          {breakpoints.md ? (
            <LogoSmall className="logo" />
          ) : (
            <LogoR className="logo" />
          )}
        </div>
        <div className="info-primary">
          <div className="links">
            <Link to={`/contacts/`}>
              <p>{text4}</p>
            </Link>
            <Link to={`/about/`}>
              <p>{text5}</p>
            </Link>
            {/* <Link to={`/blog/`}>
              <p>{text6}</p>
            </Link> */}
          </div>
        </div>
        {/* <div></div> */}
        <div className="info-secondary">
          <div className="links">
            <Link to={`/aftersales/`}>
              <p>{text1}</p>
            </Link>
            {lang == 'pt' &&
              <a href={denuncias.link} target="_blank" rel="noreferrer">
                <p>{denuncias.text}</p>
              </a>
            }
            <div className="p-list">
              <button
                type="button"
                className="btn-empresarial"
                value="btn-empresarial"
                ref={botaoRef}
              >
                {text2}
              </button>

              <ul className="grupo-empresarial" ref={grupoRef}>
                <li className="d1" ref={d1Ref}>
                  <a
                    href="https://www.exporlux.pt/home"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Exporlux
                  </a>
                </li>
                <li className="d2" ref={d3Ref}>
                  <a
                    href="https://www.soneres.pt/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Soneres
                  </a>
                </li>
                <li className="d3" ref={d2Ref}>
                  <a
                    href="http://www.lightdesign.com.br/index.php"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Light Design
                  </a>
                </li>
                <li className="d4" ref={d4Ref}>
                  <a
                    href="http://www.soneres.com.br"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Soneres Brasil
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div></div>
        <div></div> */}
        <div className="social-links">
          <div>
            <a
              href={link_facebook}
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Facebook"
            >
              <LogoFacebook />
            </a>
            <a
              href={link_insta}
              rel="noreferrer noopener"
              target="_blank"
              aria-label="Instagram"
            >
              <LogoInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/ramalhos/"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="LinkedIn"
            >
              <LogoLinkedIn />
            </a>
            <a
              href="https://www.youtube.com/channel/UCbyt5AhYkuuglVvD1QKDhwg/"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="YouTube"
            >
              <LogoYoutube />
            </a>
          </div>
        </div>
      </div>
      <div className="under-footer">
        <div className="copyright">
          <span>{text3}</span>
          <a
            href="https://invisual.pt/"
            rel="noreferrer noopener"
            target="_blank"
            className="invisual-container"
          >
            Created: Invisual.pt
          </a>
        </div>
        {lang !== 'mx' ??
          <div className="pt2020-container">
            <a
              href="/download/ficha_projeto_poci-02-0752-feder-034274_poci-02-0853-feder-000597_poci-02-0853-feder-041263.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={'/images/pt2020_t.png'}
                alt='pt2020'
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center center'
                }}
              />
            </a>
          </div>
        }

      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  width: 100%;
  color: ${color.white};
  background-color: ${color.red};
  z-index: 4;
  padding-top: 25px;

  .footer-container {
    max-width: 2000px;
    margin: 0 auto;
    height: fit-content;

    @media ${device.mobileP} {
      display: grid;
      grid-template-areas:
        "logo logo"
        "first second"
        "social social";
      grid-row-gap: 20px;
      width: 90%;
    }

    @media ${device.tabletL} {
      display: flex;
      width: 90%;
      max-width: 900px;
      justify-content: space-between;
    }

    @media ${device.desktop} {
      padding: 50px 0 10px 0;
    }
  }

  .logo {
    fill: ${color.white};
    grid-area: logo;
    width: 40px;
    height: auto;
  }

  p,
  .p-list,
  .copyright {
    ${font.robotoMedium};
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .info-primary {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-area: first;

    @media ${device.mobileP} {
      grid-row-gap: 35px;
    }

    @media ${device.desktop} {
      grid-row-gap: none;
    }
  }
  .info-secondary {
    grid-area: second;
  }
  .info-primary .links {
    align-self: start;
  }
  .info-primary .address {
    align-self: end;
  }

  .social-links {
    display: grid;
    align-items: start;
    justify-self: left;
    grid-area: social;

    @media ${device.mobileP} {
      width: 100%;
      justify-self: center;
    }

    @media ${device.tabletL} {
      width: unset;
    }
  }

  .social-links div {
    @media ${device.mobileP} {
      display: flex;
      justify-content: center;
    }
    @media ${device.tabletP} {
      display: flex;
      justify-content: flex-start;
    }
  }

  .social-links svg {
    fill: ${color.white};

    @media ${device.mobileP} {
      margin-right: 10px;
    }

    @media ${device.desktop} {
      margin-right: 15px;
    }
  }
  .social-links a:last-of-type svg {
    margin-right: 0px;
  }

  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  .under-footer {
    display: grid;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;

    @media ${device.mobileP} {
      padding: 20px 0 35px 0;
      width: 90%;
      grid-template-areas:
        "copyright"
        "pt2020"
        "links";
      justify-content: center;
    }
    @media ${device.tabletP} {
      width: 90%;
      padding: 0 0 25px 0;
      grid-template-areas:
        "pt2020 pt2020 copyright copyright"
        "links links links links";
      justify-content: space-between;
    }

    .pt2020-container {
      margin: 0;
      cursor: pointer;
      grid-area: pt2020;

      @media ${device.mobileP} {
        justify-self: center;
        width: 100%;
      }
      @media ${device.tabletP} {
        justify-self: start;
        width: 29rem;
      }

      .gatsby-image-wrapper {
        width: 100%;
        transition: all 0.3s ${ease.out};
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .copyright {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      grid-area: copyright;
      text-align: right;

      .invisual-container {
        position: relative;
        margin: 0;
        align-self: flex-end;
        cursor: pointer;
      }
      .gatsby-image-wrapper {
        width: 100%;
        transition: all 0.3s ${ease.out};
        &:hover {
          opacity: 0.5;
        }
      }

      @media ${device.mobileP} {
        justify-self: center;
        margin-bottom: 15px;
      }
      @media ${device.tabletP} {
        justify-self: end;
        margin-bottom: 0px;
      }
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    outline: 0;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .grupo-empresarial {
    display: none;
    margin-left: 15px;
    color: #fff;
    transition: all 0.2s ${ease.out};
  }

  .grupo-empresarial:hover {
    color: rgba(255, 255, 255, 0.596);
    transition: all 0.2s ${ease.out};
  }

  .d1,
  .d2,
  .d3,
  .d4 {
    line-height: 1.5;
  }

  .d1:hover,
  .d2:hover,
  .d3:hover,
  .d4:hover {
    color: #fff;
    transition: all 0.2s ${ease.out};
  }

  .d1 {
    opacity: 0;
    transition: opacity 0.6s;
  }

  .d2 {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .d3 {
    opacity: 0;
    transition: opacity 0.4s;
  }

  .d4 {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .d1.active {
    opacity: 1;
    transition: opacity 0.3s;
  }
  .d2.active {
    opacity: 1;
    transition: opacity 0.4s;
  }
  .d3.active {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .d4.active {
    opacity: 1;
    transition: opacity 0.6s;
  }
`

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
