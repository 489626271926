import React from "react"
import styled from "styled-components"
import { color, device } from "../../layout/global"
// import { Link } from "gatsby"
import Arrow from "../../images/Products/right-arrow.svg"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

const Lang = ({ activeLanguage, location, isDarkened, hideBr }) => {
  const { languages, originalPath } = useI18next()


  return (
    <Wrapper isDarkened={isDarkened} hideBr={hideBr} >
      <button className="dropbtn">
        {activeLanguage} <Arrow />
      </button>
      <div className="dropdown-content">
        {languages.map(
          lng =>
            !(lng === activeLanguage) && (
              <Link
                to={originalPath}
                language={lng}
                className={lng + " nav-link"}
                alt="Language"
                partiallyActive
                activeClassName="active"
                key={lng}
              >
                {lng}
              </Link>
            )
        )}
      </div>
    </Wrapper>
  )
}

export default Lang

const Wrapper = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 2.5rem;
  z-index: 3;
  transition: all 0.3s ease-in-out;

  .dropbtn {
    color: ${props => (props.isDarkened ? color.greyDark : color.white)};
    font-size: 1.2rem;
    text-transform: uppercase;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    color: ${color.white};
    top: 12px;
    transform: translate(-25%, 0);

    ${props => props.hideBr && ".br {display:none;}"}

    @media ${device.mobileP} {
      margin-left: 10px;
    }

    @media ${device.tabletP} {
      margin-left: 0;
    }
  }

  .dropdown-content a {
    color: ${props => (props.isDarkened ? color.greyDark : color.white)};
    display: block;
    margin: 0;
    padding: 13px;
    height: 0;
  }

  &:hover .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .dropdown-content:hover .nav-link {
    opacity: 0.5;
  }
  .dropdown-content:hover .nav-link:hover {
    opacity: 1;
  }

  svg {
    transform: rotate(90deg);
    fill: #fff;
    width: 5px;
    margin-left: 5px;
  }
`
