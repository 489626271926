import React, { useEffect } from "react"
import Sidebar from "react-sidebar"
import SidebarWrapper from "./style"
import { Link } from "gatsby"

import MassaMadreSVG from "../../images/MassaMadre.svg"

import { handleClick } from "../../utils/custom"

import {
  disablePageScroll,
  enablePageScroll,
  addFillGapSelector,
} from "scroll-lock"

const SidebarMenu = ({ isOpen, setOpen, onSetOpen, content, activeLanguage, contentMsg }) => {

  useEffect(() => {
    // componentDidMount e componentDidUpdate
    if (isOpen) {
      disablePageScroll()
      addFillGapSelector(".nav-primary")
    } else {
      enablePageScroll()
    }

    // add class on hover and on leave

    let pagesChild = document.querySelector(".pages").children

    for (let i = 0; i < pagesChild.length; i++) {
      pagesChild[i].addEventListener("mouseenter", function () {
        pagesChild[i].classList.add("active")
        pagesChild[i].classList.remove("reverse")
      })
      pagesChild[i].addEventListener("mouseleave", function () {
        pagesChild[i].classList.remove("active")
        pagesChild[i].classList.add("reverse")
        setTimeout(function () {
          pagesChild[i].classList.remove("reverse")
        }, 1000)
      })
    }
  }, [isOpen])
  return (
    <SidebarWrapper>
      <Sidebar
        open={isOpen}
        onSetOpen={onSetOpen}
        pullRight={true}
        shadow={false}
        rootClassName="root"
        sidebarClassName="sidebar"
        overlayId="overlay"
        contentClassName="content"
        children={<></>}
        sidebar={
          <div className="wrapper-content">
            <div className="pages">
              {content['pageLinks'].map(pageLinks => (
                pageLinks.outbound ? (
                  <a
                    href={pageLinks.url}
                    key={pageLinks.name}
                    id={pageLinks.name}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {pageLinks.name}
                  </a>
                )
                  : (
                    <Link
                      key={pageLinks.name}
                      to={pageLinks.url}
                      className={pageLinks.name}
                      style={{ display: pageLinks.name === "Blog" && "none" }}
                    >
                      {pageLinks.name}
                    </Link>
                  )
              ))}
            </div>
            <div className="others">
              <div className="second-block">
                {content['secondBlockLeft'].map(secondBlockLeft => (
                  <a
                    key={secondBlockLeft.name}
                    href={secondBlockLeft.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={secondBlockLeft.class}
                  >
                    {secondBlockLeft.name}
                  </a>
                ))}
              </div>
              <div className="third-block">
                {content['thirdBlock'].map(thirdBlock => (
                  <a onClick={() => handleClick(thirdBlock.url, activeLanguage)}
                    key={thirdBlock.name}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                    style={{cursor: 'pointer'}}
                  >
                    {thirdBlock.name}
                    {thirdBlock.name === '+351 234 630 200' &&
                      <><br /><span><small style={{ fontSize: "1vh", 
                      textTransform: "capitalize", fontStyle: "italic"}}>{contentMsg.fixo}</small></span></>}
                  </a>
                ))}
              </div>
              <div className="fourth-block">
                {content['fourthBlock'].map(fourthBlock => (
                  <a
                    key={fourthBlock.name}
                    href={fourthBlock.url}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    {fourthBlock.name}
                  </a>
                ))}
              </div>
              {activeLanguage === 'br' &&
                <a
                  href="https://massamadreblog.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="massa-madre"
                >
                  <MassaMadreSVG />
                </a>
              }
            </div>
          </div>
        }
      ></Sidebar>
    </SidebarWrapper>
  )
}

export default SidebarMenu
