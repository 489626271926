import { font, color, ease, device } from "../../layout/global"
import styled from "styled-components"

const WrapperBottom = styled.header`
width: 100%;
height: 8vh;
z-index: 5;
position: fixed;
opacity: 1;
bottom: 0;
display: flex;
align-items: center;
p,a{
  color: ${props =>
    props.sidebarLinksColor
      ? color.white
      : props.bottomIsDarkened
        ? color.greyDark
        : color.white};
${font.robotoMedium};
}

.nav-link{
  z-index: 3;
  text-align: center;
  width: max-content;   
  display: flex;
  align-items: center;     
  margin: auto;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none; 
}

.hvr-overline-from-center.active > div {
  position: relative;

  &:after {
    content: "";
    height: 2px;
    width: 50px;
    background: ${color.red};
    display: block;
    margin: 0 auto;
  }
}

.swiper-slide{
  width: 27% !important;
  min-width: max-content !important;
  text-align: center;
}
`

export default WrapperBottom
