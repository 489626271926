import React from 'react'

import { ConsentGtag } from './consentGtag'

export const handleClick = (url, lng) => {
    ConsentGtag()
    if (typeof window !== "undefined") {
        if (url.includes('wa.me'))
            window.open(url, '_blank', 'noreferrer')
        else
            window.open(url, '_self', 'noreferrer')
        window.gtag('event', 'chamadas', {
            pais: lng,
        })
    }
}
