import { font, color, ease, device } from "../../layout/global"
import styled from "styled-components"

const Wrapper = styled.header`
  width: 100%;
  height: 75px;
  z-index: 5;
  position: fixed;
  opacity: 1;
  transition: all 0.3s ${ease.out};

  .header-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    ${font.robotoMedium};
    margin: 0 20px;
    height: 75px;
  }

  .logo-container {
    width: 200px;
    svg {
      transition: all ${ease.out} 0.3s !important;
      fill: ${props =>
        props.sidebarLinksColor
          ? color.white
          : props.isDarkened
          ? color.greyDark
          : color.white};
    }
    z-index: 3;
  }

  .nav-primary {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
  }

  .dropbtn {
    color: ${props =>
      props.sidebarLinksColor
        ? color.white
        : props.isDarkened
        ? color.greyDark
        : color.white};

    svg {
      fill: ${props =>
        props.sidebarLinksColor
          ? color.white
          : props.isDarkened
          ? color.greyDark
          : color.white};
    }
  }

  .nav-bottom{
      .slick-track{
          display:flex;
      }
  }
  
  .nav-bottom {
    opacity: ${props => (props.blog ? 1 : 0)};
    pointer-events: ${props => (props.blog ? "all" : "none")};
    transition: all 0.3s ease-out;

    @media ${device.mobileP} {
      width: 115%;
    }

    @media ${device.mobileL} {
      width: 110%;
    }

    @media ${device.tabletP} {
      width: 108%;
    }

    @media ${device.tabletL} {
      width: 106%;
    }
  }

  .drag {
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    margin-bottom: 10px;

    p {
      font-size: 1rem;
      color: ${color.grey};
      ${font.roboto400};
      margin-left: 15px;
      display: flex;
      align-items: center;

      svg {
        fill: ${color.grey};
        width: 10px;
        margin-left: 10px;
      }
    }
  }

  .bottom-wrapper {
    display: ${props => (props.hideHeaderMobileBottom ? "none" : "flex")};
    align-items: center;
    position: fixed;
    bottom: 0;
    ${font.robotoMedium};
  }

  .swiper-slide{
    width: 23vw !important;
    min-width: max-content !important;
    text-align: center;
  }

  .nav-secondary {
    display: flex;
    flex-direction: row;
    position: relative;

    .search-container {
      z-index: 3;

      svg {
        height: auto;
        width: 16px;
        transition: all ${ease.out} 0.3s !important;
        fill: ${props =>
          props.sidebarLinksColor
            ? color.whiteOpacity
            : props.isDarkened
            ? color.greyDark
            : color.white};
      }

      .search-submit {
        display: flex;
        align-items: center;

        .search-submit:hover svg {
          fill: ${props => (props.sidebarLinksColor ? color.white : color.red)};
        }
      }

      .search {
        border: none;
        outline: none;
        background: none;
        border-bottom: 1px solid;
        border-color: ${props =>
          props.sidebarLinksColor
            ? color.whiteOpacity
            : props.isDarkened
            ? color.greyDark
            : color.whiteOpacity};
        position: absolute;
        right: 55px;
        width: 0;
        transition: width 0.3s ease-in-out, opacity 0.6s ease-in-out;
        opacity: 0;
        pointer-events: none;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-style: normal;
        color: ${props =>
          props.sidebarLinksColor
            ? color.white
            : props.location === "/assistencia"
            ? color.whiteOpacity
            : props.isDarkened && props.location !== "/assistencia"
            ? color.greyDark
            : color.white};
        letter-spacing: 1.5px;
      }

      .active-input {
        width: 60vw;
        transition: width 0.3s ease-in-out;
        opacity: 100%;
        pointer-events: auto;
        transform: translateY(-8px);
      }

      .search-list {
        position: absolute;
        width: 60vw;
        right: 55px;
        list-style-type: none;
        padding: 0;
        margin-top: 30px;

        li {
          margin: 5px 0;
        }
      }
    }
  }

  .opacity {
    opacity: 0 !important;
  }

  .display-none {
    display: none !important;
  }

  .close-search {
    width: 20px;
    z-index: 3 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover:before,
    &:hover:after,
    &:hover div {
      background-color: ${color.red};
    }
  }

  .close-search:after,
  .close-search:before,
  .close-search div {
    background-color: ${props =>
      props.sidebarLinksColor
        ? color.white
        : props.location === "/assistencia"
        ? color.whiteOpacity
        : props.isDarkened && props.location !== "/assistencia"
        ? color.greyDark
        : color.white};
    border-radius: 3px;
    content: "";
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: all 0.2s ease-in-out;
  }

  .close-search:before {
    transform: translateY(6px) rotate(135deg);
  }

  .close-search:after {
    transform: translateY(-6px) rotate(-135deg);
  }

  .close-search div {
    transform: scale(0);
  }

  .nav-link {
    z-index: 3;
  }

  .nav-bottom .nav-link {
    text-align: center;
    color: ${props =>
      props.sidebarLinksColor
        ? color.white
        : props.bottomIsDarkened
        ? color.greyDark
        : color.white};
    width: max-content;
  }

  .nav-link,
  .nav-secondary {
    display: flex;
    align-items: center;
    color: ${props =>
      props.sidebarLinksColor
        ? color.whiteOpacity
        : props.location === "/assistencia"
        ? color.whiteOpacity
        : props.isDarkened && props.location !== "/assistencia"
        ? color.greyDark
        : color.white};
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: color ${ease.out} 0.3s;
    opacity: ${props =>
      !props.isDarkened && props.location === "/assistencia" ? 0 : 1};
    pointer-events: all;
    opacity: 100%;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.2s;

    &:hover {
      color: ${props => props.sidebarLinksColor && color.white};
    }
  }

  .hvr-overline-from-center.active > div {
    position: relative;

    &:after {
      content: "";
      height: 2px;
      width: 50px;
      background: ${color.red};
      display: block;
      margin: 0 auto;
    }
  }

  /* HIDE AND SHOW SCROLL */

  .headroom {
    will-change: transform;
    transition: transform 400ms linear;
    position: relative;
    /*backdrop-filter: blur(3px);*/
  }

  .headroom--pinned {
    transform: translateY(0%);
  }

  .headroom--unpinned {
    transform: translateY(-100%);
  }

  /* --- */

  /* HAMBURGUER */

  .nav-secondary button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    z-index: 2;
    margin-left: 10px;
    padding: 0;

    &:focus {
      outline: 0;
    }

    .hamburguer-icon {
      width: 20px;

      &:hover:before,
      &:hover:after,
      &:hover div {
        background-color: ${props =>
          props.sidebarLinksColor ? color.white : color.red};
      }
    }

    .hamburguer-icon:after,
    .hamburguer-icon:before,
    .hamburguer-icon div {
      background-color: ${props =>
        props.sidebarLinksColor
          ? color.white
          : props.location === "/assistencia"
          ? color.whiteOpacity
          : props.isDarkened && props.location !== "/assistencia"
          ? color.greyDark
          : color.white};
      border-radius: 3px;
      content: "";
      display: block;
      height: 2px;
      margin: 4px 0;
      transition: all 0.2s ease-in-out;
    }

    .hamburguer-icon.active:before {
      transform: translateY(6px) rotate(135deg);
    }

    .hamburguer-icon.active:after {
      transform: translateY(-6px) rotate(-135deg);
    }

    .hamburguer-icon.active div {
      transform: scale(0);
    }
  }
`

export default Wrapper
