import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import SidebarMenu from "../Sidebar"
import Headroom from "react-headroom"
import * as JsSearch from "js-search"
import LangDropdown from "../LangDropdown"
import { useWindowSize } from "@react-hook/window-size/throttled"
import { Link } from "gatsby-plugin-react-i18next"

import Wrapper from "./styleMobile"
import WrapperBottom from "./styleMobileBottom"

import Logo from "../../images/Header/small-logo.svg"
import SearchSVG from "../../images/Header/search.svg"

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'// import required modules

import {
  Microram,
  Turboram,
  Rotoram,
  Modulram,
  Compactram,
  Electram,
  Anelar,
} from "./searchTerms"

// COMPONENT
const HeaderTest = ({  location, content, activeLanguage, hideBr }) => {

  const [isDarkened, setIsDarkened] = useState(false)
  const [bottomIsDarkened, setBottomIsDarkened] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [blogSideBar, setBlogSideBar] = useState(true)
  const SearchButtonRef = useRef(null)
  const CloseButtonRef = useRef(null)
  const InputRef = useRef(null)
  const [searchValue, setSearch] = useState(null)
  const [width, height] = useWindowSize()

  // CHANGE HEADER COLOR

  let colorDiv = "#introduction"
  let secondColorDiv = "#form-contact"

  const colorPositionStart = useRef(null),
    colorPositionEnd = useRef(null),
    secondColorPositionStart = useRef(null),
    secondColorPositionEnd = useRef(null),
    introExists = useRef(null),
    formExists = useRef(null)


  useEffect(() => {
    let colorDivX = document.querySelector(colorDiv)
    let secondColorDivX = document.querySelector(secondColorDiv)

    colorDivX && (colorPositionStart.current = colorDivX.offsetTop)
    colorDivX && (colorPositionEnd.current = colorDivX.offsetHeight)
    secondColorDivX &&
      (secondColorPositionStart.current = secondColorDivX.offsetTop)
    secondColorDivX &&
      (secondColorPositionEnd.current = secondColorDivX.offsetHeight)

    colorDivX ? (introExists.current = true) : (introExists.current = false)
    secondColorDivX ? (formExists.current = true) : (formExists.current = false)
  }, [width, height, colorDiv, secondColorDiv])

  useEffect(() => {
    if (
      window.location.pathname.includes("about") ||
      window.location.pathname.includes("blog") ||
      window.location.pathname.indexOf("blog") > -1
    ) {
      setIsDarkened(true)
    }
    if (window.location.pathname.indexOf("blog") > -1) {
      setIsDarkened(true)
      setBottomIsDarkened(true)
    }
  }, [])

  // GET SCROLL POSITION AND CHANGE COLOR
  useScrollPosition(
    ({ currPos }) => {
      let position = Math.abs(currPos.y)
      let bottomMenu = position + colorPositionStart.current

      // CHANGE HEADER MENU COLOR
      if (
        window.location.pathname.includes("about") ||
        window.location.pathname.includes("blog") ||
        window.location.pathname.indexOf("blog") > -1
      ) {
        setIsDarkened(true)
      } else if (
        window.location.pathname.includes("contacts") ||
        window.location.pathname.includes === "aftersales"
      ) {
        position <= secondColorPositionStart.current
          ? setIsDarkened(false)
          : position <=
            secondColorPositionStart.current + secondColorPositionEnd.current
            ? setIsDarkened(true)
            : setIsDarkened(false)
      } else {
        if (introExists.current === false || formExists.current === false) {
          setIsDarkened(false)
        } else {
          position <= colorPositionStart.current
            ? setIsDarkened(false)
            : position <= colorPositionStart.current + colorPositionEnd.current
              ? setIsDarkened(true)
              : position <= secondColorPositionStart.current
                ? setIsDarkened(false)
                : position <=
                  secondColorPositionStart.current + secondColorPositionEnd.current
                  ? setIsDarkened(true)
                  : setIsDarkened(false)
        }
      }

      // CHANGE BOTTOM MENU COLOR (PRODUCTS)
      if (
        window.location.pathname.includes("contacts") ||
        window.location.pathname.includes("aftersales")
      ) {
        bottomMenu <= secondColorPositionStart.current
          ? setBottomIsDarkened(false)
          : bottomMenu <=
            secondColorPositionStart.current + secondColorPositionEnd.current
            ? setBottomIsDarkened(true)
            : setBottomIsDarkened(false)
      } else {
        if (introExists.current === false || formExists.current === false) {
          setBottomIsDarkened(false)
        } else {
          bottomMenu <= colorPositionStart.current
            ? setBottomIsDarkened(false)
            : bottomMenu <=
              colorPositionStart.current + colorPositionEnd.current
              ? setBottomIsDarkened(true)
              : bottomMenu <= secondColorPositionStart.current
                ? setBottomIsDarkened(false)
                : bottomMenu <=
                  secondColorPositionStart.current + secondColorPositionEnd.current
                  ? setBottomIsDarkened(true)
                  : setBottomIsDarkened(false)
        }
      }
      if (window.location.pathname.indexOf("blog") > -1) {
        setIsDarkened(true)
        setBottomIsDarkened(true)
      }
    },
    [isDarkened, bottomIsDarkened]
  )
  // ---

  function onSetSidebarOpen(open) {
    setSidebarOpen(open)
  }

  useEffect(() => {
    if (sidebarOpen) {
      document.querySelector(".hamburguer-icon").classList.add("active")
      if (window.location.pathname.indexOf("blog") > -1) {
        setBlogSideBar(true)
      }
    } else {
      document.querySelector(".hamburguer-icon").classList.remove("active")
      if (window.location.pathname.indexOf("blog") > -1) {
        setBlogSideBar(false)
      }
    }
  }, [sidebarOpen])

  useEffect(() => {
    let searchButton = SearchButtonRef.current
    let closeButton = CloseButtonRef.current
    let inputText = InputRef.current

    // SEARCH (https://github.com/bvaughn/js-search)
    const search = new JsSearch.Search("id")
    search.addIndex("title_" + activeLanguage)
    search.addIndex("tags_" + activeLanguage)
    search.addIndex("url")
    search.addDocuments([
      Microram,
      Turboram,
      Rotoram,
      Modulram,
      Compactram,
      Electram,
      Anelar,
    ])

    // Click do botão search
    searchButton.addEventListener("click", function () {
      if (
        !document.querySelector(".search").classList.contains("active-input")
      ) {
        document.querySelector(".search").classList.add("active-input")
        document.querySelector(".search-submit").classList.add("display-none")
        document.querySelector(".search-list").classList.remove("opacity")
        document.querySelector(".dropbtn").classList.add("opacity")
        document.querySelector(".close-search").classList.remove("display-none")
        setTimeout(function () {
          document.querySelector(".search-submit").type = "submit"
        }, 50)
        document.querySelector(".search-submit").disabled = true

        let links = document.querySelectorAll(".nav-link")

        for (let l = 0; l < links.length; l++) {
          links[l].classList.add("opacity")
        }
      }
    })

    // Check if input not empty
    inputText.addEventListener("keyup", function () {
      if (inputText.value === "") {
        document.querySelector(".search-submit").disabled = true
      } else {
        document.querySelector(".search-submit").disabled = false
      }
      setSearch({
        searchValue: search
          .search(inputText.value)
          .map(s => ({
            title:
              activeLanguage === 'pt' || activeLanguage === 'br' ?
                s.title_pt :
                activeLanguage === 'en' ?
                  s.title_en :
                  activeLanguage === 'es' ?
                    s.title_es :
                    activeLanguage === 'fr' &&
                    s.title_fr,
            url: s.url
          })),
      })
    })

    closeButton.addEventListener("click", function () {
      document.querySelector(".close-search").classList.add("display-none")
      document.querySelector(".search-submit").classList.remove("display-none")
      document.querySelector(".search-list").classList.add("opacity")
      document.querySelector(".dropbtn").classList.remove("opacity")
      document.querySelector(".search").classList.remove("active-input")
      document.querySelector(".search-submit").type = "button"
      document.querySelector(".search-submit").disabled = false

      let links = document.querySelectorAll(".nav-link")
      for (let l = 0; l < links.length; l++) {
        links[l].classList.remove("opacity")
      }
    })
  }, [activeLanguage])

  // SLIDER BOTTOM MENU

  let slideInicial
  
  location.pathname.includes("microram") === true && (slideInicial = 0)
  location.pathname.includes("turboram") === true && (slideInicial = 1)
  location.pathname.includes("rotoram") === true && (slideInicial = 2)
  location.pathname.includes("modulram") === true && (slideInicial = 3)
  location.pathname.includes("compactram") === true && (slideInicial = 4)
  location.pathname.includes("electram") === true && (slideInicial = 5)
  location.pathname.includes("anelar") === true && (slideInicial = 6)
  location.pathname.includes("fermentation") === true && (slideInicial = 7)
  location.pathname.includes("proofer") === true && (slideInicial = 8)
  location.pathname.includes("cold") === true && (slideInicial = 9)
  location.pathname.includes("nata") === true && (slideInicial = 10)
  location.pathname.includes("kebab") === true && (slideInicial = 11)

  
  return (
    <>
      <Wrapper
        isDarkened={isDarkened}
        bottomIsDarkened={bottomIsDarkened}
        location={location.pathname}
        sidebarLinksColor={sidebarOpen}
        id="header"
        key={window.location.pathname}
        blog={blogSideBar}
      >
        <Headroom
          disableInlineStyles
          style={{
            WebkitTransition: "all 1s ease-in-out",
            NozTransition: "all 1s ease-in-out",
            OTransition: "all 1s ease-in-out",
            transition: "all 1s ease-in-out",
          }}
          onUnpin={() => {
            document.querySelector("header").style.pointerEvents = "none"
          }}
          onPin={() => {
            document.querySelector("header").style.pointerEvents = "all"
          }}
        >
          <div className="header-wrapper">
            <div className="logo-container">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <nav className="nav-primary">
              <nav className="nav-secondary">
                <LangDropdown
                  activeLanguage={activeLanguage}
                  location={location}
                  hideBr={hideBr}
                  isDarkened={isDarkened}
                />
                <div className="search-container">
                  <form action="" autoComplete="on">
                    <input
                      className="search"
                      list="search-keywords"
                      name="search"
                      placeholder=""
                      autoComplete="off"
                      ref={InputRef}
                    />
                    <ul className="search-list">
                      {searchValue !== null &&
                        searchValue.searchValue.map(ele => (
                          <li key={ele.title} value={ele.title}>
                            <Link to={`/${ele.url}`}>{ele.title}</Link>
                          </li>
                        ))}
                    </ul>
                    <button
                      type="button"
                      className="search-submit"
                      value="search-submit"
                      ref={SearchButtonRef}
                    >
                      <SearchSVG />
                    </button>
                  </form>
                </div>
                <button
                  type="button"
                  className="close-search display-none"
                  ref={CloseButtonRef}
                >
                  <div></div>
                </button>
                <SidebarMenu
                  isOpen={sidebarOpen}
                  setOpen={setSidebarOpen}
                  onSetOpen={onSetSidebarOpen}
                  content={content['sidebar']}
                  contentMsg={content['msgMarkPhone']}
                  activeLanguage={activeLanguage}
                />
                <button
                  className="btn-menu"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="hamburguer-icon">
                    <div></div>
                  </div>
                </button>
              </nav>
            </nav>
          </div>
        </Headroom>
      </Wrapper>
      <WrapperBottom
         isDarkened={isDarkened}
         bottomIsDarkened={bottomIsDarkened}
         location={location.pathname}
         sidebarLinksColor={sidebarOpen}
         id="header"
         key={window.location.pathname}
         blog={blogSideBar}>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          initialSlide={slideInicial}
          centeredSlides={true}
          className="mySwiper swiper-horizontal swiper"
        >
          {content['mainLinksMobile'].map((x, index) => (
            <SwiperSlide key={index}>
              {x.outbound === false ?
                <Link
                  key={x.name}
                  to={x.url}
                  className="nav-link hvr-overline-from-center"
                  partiallyActive
                  activeClassName="active"
                >
                  <div>{x.name}</div>
                </Link>
                :
                <a
                  key={x.name}
                  href={x.url}
                  className="nav-link hvr-overline-from-center"
                  partiallyActive
                  activeClassName="active"
                  target='_blank'
                  rel='noreferrer'
                >
                  <div>{x.name}</div>
                </a>
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </WrapperBottom>
    </>
  )
}

// PROP-TYPES
HeaderTest.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderTest.defaultProps = {
  siteTitle: ``,
}

export default HeaderTest
