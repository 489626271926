export const Microram = {
  id: 0,
  title_pt: "Microram - Fornos de micro produções",
  title_en: "Microram - Micro production ovens",
  title_es: "Microram - Hornos de micro producción",
  title_fr: "Microram - Fours de microproduction",
  url: "products/microram",
  tags_pt: [
    "fornos",
    "elétrico",
    "eléctrico",
    "reduzido",
    "pequeno",
    "padaria",
    "pastelaria",
    "fácil",
    "inox",
    "turbinas",
    "auto inversão",
    "ventilação",
    "vapor",
    "massa congelada",
    "vidro duplo",
  ],
  tags_en: [
    "ovens",
    "electric",
    "electric",
    "reduced",
    "small",
    "bakery",
    "pastries",
    "easy",
    "stainless",
    "turbines",
    "self inversion",
    "ventilation",
    "steam",
    "frozen pasta",
    "double glass",
  ],
  tags_es: [
    "hornos",
    "eléctrico",
    "eléctrico",
    "reducido",
    "Pequeño",
    "panadería",
    "panadería",
    "fácil",
    "acero inoxidable",
    "turbinas",
    "Auto-reversión",
    "Ventilación",
    "Vapor",
    "pasta congelada",
    "vidrio doble",
  ],
  tags_fr: [
    "fours",
    "électrique",
    "électrique",
    "réduit",
    "petit",
    "boulangerie",
    "facile",
    "acier inoxydable",
    "turbines",
    "auto-inversion",
    "ventilation",
    "vapeur",
    "pâtes congelées",
    "double verre",
  ],
}

export const Turboram = {
  id: 1,
  title_pt: "Turboram - Fornos ventilados",
  title_en: "Turboram - Convection ovens",
  title_es: "Turboram - Hornos de convección",
  title_fr: "Turboram - Fours ventilés",
  url: "products/turboram",
  tags_pt: [
    "fornos",
    "fixo",
    "ventilação",
    "ventilador",
    "autolavagem",
    "auto-lavagem",
    "rápido",
    "vidro duplo",
    "calhas removíveis",
    "porta automática",
    "painel de controlo",
    "massa congelada",
    "conveção",
  ],
  tags_en: [
    "ovens",
    "fixed",
    "ventilation",
    "fan",
    "autowash",
    "autowash",
    "fast",
    "double glass",
    "removable rails",
    "automatic door",
    "control panel",
    "frozen pasta",
    "convetion",
  ],
  tags_es: [
    "hornos",
    "arreglado",
    "Ventilación",
    "fanático",
    "auto lavado",
    "auto lavado",
    "rápido",
    "vidrio doble",
    "rieles desmontables",
    "puerta automática",
    "Panel de control",
    "pasta congelada",
    "La transmisión",
  ],
  tags_fr: [
    "fours",
    "fixé",
    "ventilation",
    "fan",
    "autowash",
    "autowash",
    "rapide",
    "double verre",
    "rails amovibles",
    "porte automatique",
    "panneau de contrôle",
    "pâtes congelées",
    "convetion",
  ],
}

export const Rotoram = {
  id: 2,
  title_pt: "Rotoram - Fornos rotativos",
  title_en: "Rotoram - Rotating rack ovens",
  title_es: "Rotoram - Hornos rotativos",
  title_fr: "Rotoram - Fours à chariot rotatif",
  url: "products/rotoram",
  tags_pt: [
    "fornos",
    "rotativo",
    "grande",
    "vapor",
    "alto rendimento",
    "aço",
    "painel de controlo",
    "inox",
    "vidro duplo",
    "elevada temperatura",
    "corte térmico",
    "360",
    "gancho",
    "plataforma",
    "elétrico",
    "biocombustível",
    "gás",
    "gasóleo",
  ],
  tags_en: [
    "ovens",
    "rotating",
    "big",
    "steam",
    "high performance",
    "steel",
    "control panel",
    "stainless",
    "double glass",
    "high temperature",
    "thermal cut",
    "360",
    "hook",
    "platform",
    "electric",
    "biofuel",
    "gas",
    "diesel",
  ],
  tags_es: [
    "hornos",
    "girando",
    "grande",
    "Vapor",
    "alto rendimiento",
    "Acero",
    "Panel de control",
    "acero inoxidable",
    "vidrio doble",
    "alta temperatura",
    "corte térmico",
    "360",
    "gancho",
    "Plataforma",
    "eléctrico",
    "biocombustible",
    "gas",
    "Diesel",
  ],
  tags_fr: [
    "fours",
    "tournant",
    "grand",
    "vapeur",
    "haute performance",
    "acier",
    "panneau de contrôle",
    "acier inoxydable",
    "double verre",
    "haute température",
    "coupure thermique",
    "360",
    "crochet",
    "plate-forme",
    "électrique",
    "biocarburant",
    "gaz",
    "diesel",
  ],
}

export const Modulram = {
  id: 3,
  title_pt: "Modulram - Fornos modulares",
  title_en: "Modulram - Modular ovens",
  title_es: "Modulram - Hornos modulares",
  title_fr: "Modulram - Fours modulables",
  url: "products/modulram",
  tags_pt: [
    "fornos",
    "modular",
    "compactos",
    "elétrico",
    "grande",
    "massa mãe",
    "pão artesanal",
    "injeção de vapor",
    "vidro temperado",
    "painel de controlo",
  ],
  tags_en: [
    "ovens",
    "modular",
    "compact",
    "electric",
    "big",
    "pasta mother",
    "homemade bread",
    "steam injection",
    "tempered glass",
    "control panel",
  ],
  tags_es: [
    "hornos",
    "modular",
    "compacto",
    "eléctrico",
    "grande",
    "Madre de la pasta",
    "pan casero",
    "Inyección de vapor",
    "Vidrio templado",
    "Panel de control",
  ],
  tags_fr: [
    "fours",
    "modulaire",
    "compact",
    "électrique",
    "grand",
    "Les pâtes de la mère",
    "pain fait maison",
    "injection de vapeur",
    "Verre trempé",
    "panneau de contrôle",
  ],
}

export const Compactram = {
  id: 4,
  title_pt: "Compactram - Fornos compactos",
  title_en: "Compactram - Compact ovens",
  title_es: "Compactram - Hornos compactos",
  title_fr: "Compactram - Fours compact",
  url: "products/compactram",
  tags_pt: [
    "fornos",
    "compacto",
    "reduzido",
    "padarias",
    "painel de controlo",
    "lcd",
    "portas abertura cima",
    "câmaras independentes",
    "resistência eléctricas",
    "elétrico",
    "vaporização",
    "eficiente",
    "ergonomia",
    "ergonómico",
  ],
  tags_en: [
    "ovens",
    "compact",
    "reduced",
    "bakeries",
    "control panel",
    "lcd",
    "doors opening up",
    "independent cameras",
    "electrical resistance",
    "electric",
    "vaporization",
    "efficient",
    "ergonomics",
    "ergonomic",
  ],
  tags_es: [
    "hornos",
    "compacto",
    "reducido",
    "panaderías",
    "Panel de control",
    "lcd",
    "las puertas se abren",
    "Cámaras independientes",
    "resistencia eléctrica",
    "eléctrico",
    "vaporización",
    "eficiente",
    "Ergonomía",
    "Ergonómico",
  ],
  tags_fr: [
    "fours",
    "compact",
    "réduit",
    "boulangeries",
    "panneau de contrôle",
    "lcd",
    "les portes s'ouvrent",
    "caméras indépendantes",
    "résistance électrique",
    "électrique",
    "vaporisation",
    "efficace",
    "ergonomie",
    "ergonomique",
  ],
}

export const Electram = {
  id: 5,
  title_pt: "Electram - Fornos de pisos eléctricos",
  title_en: "Electram - Deck ovens",
  title_es: "Electram - Hornos de pisos eléctricos",
  title_fr: "Electram - Fours à soles électriques",
  url: "products/electram",
  tags_pt: [
    "fornos",
    "eléctrico",
    "tradicional",
    "simples",
    "intuitivo",
    "painel de controlo",
    "programável",
    "eficiência",
    "vapor",
    "massas frescas",
  ],
  tags_en: [
    "ovens",
    "electric",
    "traditional",
    "simple",
    "intuitive",
    "control panel",
    "programmable",
    "efficiency",
    "steam",
    "fresh pasta",
  ],
  tags_es: [
    "hornos",
    "eléctrico",
    "tradicional",
    "simple",
    "intuitivo",
    "Panel de control",
    "programable",
    "eficiencia",
    "Vapor",
    "pasta fresca",
  ],
  tags_fr: [
    "fours",
    "électrique",
    "traditionnel",
    "simple",
    "intuitif",
    "panneau de contrôle",
    "programmable",
    "efficacité",
    "vapeur",
    "pâtes fraîches",
  ],
}

export const Anelar = {
  id: 6,
  title_pt: "Anelar - Fornos de tubos anelares",
  title_en: "Anelar - Annular tube ovens",
  title_es: "Anelar - Hornos de tubos anelares",
  title_fr: "Anelar - Fours à tubes annulaires",
  url: "products/anelar",
  tags_pt: [
    "fornos",
    "tubos",
    "anelar",
    "robusto",
    "capacidade",
    "simples",
    "simplicidade",
    "calor uniforme",
    "consecutivo",
    "artesanal",
    "retenção calor",
    "vapor",
    "longevidade",
    "inércia térmica",
    "estabilidade temperatura",
  ],
  tags_en: [
    "ovens",
    "tubes",
    "ring",
    "robust",
    "capacity",
    "simple",
    "simplicity",
    "uniform heat",
    "consecutive",
    "artisanal",
    "heat retention",
    "steam",
    "longevity",
    "thermal inertia",
    "temperature stability",
  ],
  tags_es: [
    "hornos",
    "tubos",
    "anillo",
    "robusto",
    "habilidad",
    "simple",
    "simplicidad",
    "calor uniforme",
    "consecutivos",
    "artesanal",
    "retención de calor",
    "Vapor",
    "longevidad",
    "inercia térmica",
    "estabilidad de la temperatura",
  ],
  tags_fr: [
    "fours",
    "tubes",
    "anneau",
    "robuste",
    "capacité",
    "simple",
    "simplicité",
    "chaleur uniforme",
    "consécutive",
    "artisanal",
    "rétention de chaleur",
    "vapeur",
    "longévité",
    "inertie thermique",
    "stabilité de la température",
  ],
}
