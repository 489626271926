import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Link } from "gatsby"

import { font, color, ease, device } from "../../layout/global"

import LogoR from "../../images/Footer/logo_r.svg"
import LogoSmall from "../../images/Header/small-logo.svg"
import LogoFacebook from "../../images/Footer/logo_facebook.svg"
import LogoInstagram from "../../images/Footer/logo_instagram.svg"
import LogoLinkedIn from "../../images/Footer/logo_linkedin.svg"
import LogoYoutube from "../../images/Footer/logo_youtube.svg"
import MassaMadre from "../../images/MassaMadre.svg"
// import Pt2020 from "/pt2020"

const Footer = ({ text1, text2, text3, text4, text5, text6, text7, text8, lang }) => {
  const breakpoints = useBreakpoint()

  return (
    <Wrapper>
      <div className="footer-container">
        {/* <div></div> */}
        <div>{breakpoints.md ? <LogoSmall className="logo" /> : <LogoR className="logo" />}</div>
        <div className="info-primary">
          <div className="links">
            <a onClick={() => handleClick("tel:+551934686227", 'br')} target="_blank" rel="noopener noreferrer">
              <p>{text4}</p>
            </a>
            <a href="mailto:comercial@ramalhosbrasil.com.br" target="_blank" rel="noopener noreferrer">
              <p>{text5}</p>
            </a>
            <a href="https://goo.gl/maps/owpkZRDqCeTqXqtW6" target="_blank" rel="noopener noreferrer">
              <p style={{ marginTop: "15px" }}>{text6}</p>
            </a>
            <a href="https://goo.gl/maps/owpkZRDqCeTqXqtW6" target="_blank" rel="noopener noreferrer">
              <p>{text7}</p>
            </a>
            <a href="https://goo.gl/maps/owpkZRDqCeTqXqtW6" target="_blank" rel="noopener noreferrer">
              <p>{text8}</p>
            </a>
          </div>
        </div>
        {/* <div></div> */}
        <div className="info-secondary">
          <div className="links">
            <a href="https://ramalhosbrasil.auvo.com.br/" target="_blank" rel="noopener noreferrer">
              <p>{text1}</p>
            </a>
          </div>
          <div className="links">
            <a href="https://ram.massamadreblog.com.br/quero-comprar" rel="noreferrer noopener" target="_blank">
              <p>{text2}</p>
            </a>
          </div>
        </div>
        {/* <div></div>
        <div></div> */}
        <div className="social-links">
          <div>
            <a href="https://www.facebook.com/fornosramalhos/" rel="noreferrer noopener" target="_blank" aria-label="Facebook">
              <LogoFacebook />
            </a>
            <a href="https://www.instagram.com/fornosramalhos/" rel="noreferrer noopener" target="_blank" aria-label="Instagram">
              <LogoInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/ramalhosbrasil/"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="LinkedIn"
            >
              <LogoLinkedIn />
            </a>
            <a
              href="https://www.youtube.com/channel/UCbyt5AhYkuuglVvD1QKDhwg"
              rel="noreferrer noopener"
              target="_blank"
              aria-label="YouTube"
            >
              <LogoYoutube />
            </a>
          </div>
          <div className="links massa-madre">
            <a href="https://massamadreblog.com.br/" target="_blank" rel="noopener noreferrer">
              <MassaMadre />
            </a>
          </div>
        </div>
      </div>
      <div className="under-footer">
        <div className="copyright">
          <span>{text3}</span>
          <a href="https://invisual.pt" rel="noreferrer noopener" target="_blank" className="invisual-container">
            Created: Invisual.pt
          </a>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  width: 100%;
  color: ${color.white};
  background-color: ${color.red};
  z-index: 4;
  padding-top: 25px;

  .footer-container {
    max-width: 2000px;
    margin: 0 auto;
    height: fit-content;

    @media ${device.mobileP} {
      display: grid;
      grid-template-areas:
        "logo logo"
        "first second"
        "social social";
      grid-row-gap: 40px;
      padding: 35px 0;
      width: 90%;
    }

    @media ${device.tabletL} {
      display: flex;
      width: 90%;
      max-width: 900px;
      justify-content: space-between;
    }

    @media ${device.desktop} {
      padding: 50px 0 10px 0;
    }
  }

  .logo {
    fill: ${color.white};
    grid-area: logo;
    width: 40px;
    height: auto;
  }

  p,
  .p-list,
  .copyright {
    ${font.robotoMedium};
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .info-primary {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-area: first;

    @media ${device.mobileP} {
      grid-row-gap: 35px;
    }

    @media ${device.desktop} {
      grid-row-gap: none;
    }
  }
  .info-secondary {
    grid-area: second;
  }
  .info-primary .links {
    align-self: start;
  }
  .info-primary .address {
    align-self: end;
  }

  .social-links {
    display: grid;
    align-items: start;
    justify-self: left;
    grid-area: social;

    @media ${device.mobileP} {
      width: 100%;
      justify-self: center;
    }

    @media ${device.tabletL} {
      width: unset;
    }
  }

  .social-links div {
    @media ${device.mobileP} {
      display: flex;
      justify-content: center;
    }
    @media ${device.tabletP} {
      display: flex;
      justify-content: flex-start;
    }
  }

  .social-links svg {
    fill: ${color.white};

    @media ${device.mobileP} {
      margin-right: 10px;
    }

    @media ${device.desktop} {
      margin-right: 15px;
    }
  }
  .social-links a:last-of-type svg {
    margin-right: 0px;
  }

  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  .under-footer {
    display: grid;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;

    @media ${device.mobileP} {
      padding: 35px 0 35px 0;
      width: 90%;
      grid-template-areas:
        "copyright"
        "pt2020"
        "links";
      justify-content: center;
    }
    @media ${device.tabletP} {
      width: 90%;
      padding: 0 0 25px 0;
      grid-template-areas:
        "pt2020 pt2020 copyright copyright"
        "links links links links";
      justify-content: space-between;
    }

    .pt2020-container {
      width: 290px;
      margin: 0;
      cursor: pointer;
      grid-area: pt2020;

      @media ${device.mobileP} {
        justify-self: center;
      }
      @media ${device.tabletP} {
        justify-self: start;
      }

      .gatsby-image-wrapper {
        width: 100%;
        transition: all 0.3s ${ease.out};
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .copyright {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      grid-area: copyright;
      text-align: right;

      .invisual-container {
        position: relative;
        margin: 0;
        align-self: flex-end;
        cursor: pointer;
      }
      .gatsby-image-wrapper {
        width: 100%;
        transition: all 0.3s ${ease.out};
        &:hover {
          opacity: 0.5;
        }
      }

      @media ${device.mobileP} {
        justify-self: center;
        margin-bottom: 15px;
      }
      @media ${device.tabletP} {
        justify-self: end;
        margin-bottom: 0px;
      }
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    outline: 0;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .massa-madre {
    & svg {
      margin-top: 15px;
      fill: ${color.white};
      width: 160px;
      transition: all 0.3s ${ease.out};

      &:hover {
        fill: ${color.grey};
      }
    }
  }
`

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
