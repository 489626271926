import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import styled from "styled-components"

// Components
import Header from "../components/Header"
import HeaderMobile from "../components/Header/mobile"
import Footer from "../components/Footer"
import FooterBr from '../components/Footer/br'
import CookiesEn from "../components/Cookies/en"
import CookiesEs from "../components/Cookies/es"
import CookiesFr from "../components/Cookies/fr"
import CookiesPtBr from "../components/Cookies/pt-br"
import CookiesPtPt from "../components/Cookies/pt"

import { I18nextContext } from 'gatsby-plugin-react-i18next'

import "./reset.css"
import "./layout.css"
import "./global.js"

const Layout = ({ children, location, footer, header, hideBr }) => {
  const activeLanguage = React.useContext(I18nextContext).language

  const data = useStaticQuery(graphql`
    query SiteTitlePTQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const breakpoints = useBreakpoint()

  return (
    <Wrapper>
      {breakpoints.md ? (
        <HeaderMobile
          siteTitle={data.site.siteMetadata.title}
          location={location}
          content={header}
          activeLanguage={activeLanguage}
          hideBr={hideBr}
        />
      ) : (
        <Header
          siteTitle={data.site.siteMetadata.title}
          location={location}
          content={header}
          activeLanguage={activeLanguage}
          hideBr={hideBr}
        />
      )}
      <main>{children}</main>
      {!(location.pathname === "/404/") && (
        !(activeLanguage === 'br') ?
          <Footer
            lang={activeLanguage}
            text1={footer['text1']}
            text2={footer['text2']}
            text3={footer['text3']}
            text4={footer['text4']}
            text5={footer['text5']}
            text6={footer['text6']}
            denuncias={footer['denuncias']}
          />
          :
          <FooterBr
            lang={activeLanguage}
            text1={footer['text1']}
            text2={footer['text2']}
            text3={footer['text3']}
            text4={footer['text4']}
            text5={footer['text5']}
            text6={footer['text6']}
            text7={footer['text7']}
            text8={footer['text8']}
          />
      )}
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .full-height {
    max-height: 100vh;
    overflow-x: hidden;
  }
  .full-width {
    max-width: 100%;
    overflow-y: hidden;
  }
  .white-bg {
    background-color: #ffffff;
  }
  .black-bg {
    background-color: #000000;
  }
`
