import { css } from "styled-components"

export const font = {
  robotoRegular: css`
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.42px;
  `,
  robotoMedium: css`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.2px;
  `,
  robotoBold: css`
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: bold;
    letter-spacing: normal;
  `,
  robotoBlack: css`
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: bold;
    letter-spacing: normal;
  `,
  roboto400: css`
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
  `,
  financierMedium: css`
    font-family: Financier;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0.75px;
  `,
  financierBold: css`
    font-family: Financier;
    font-weight: 700;
    font-style: italic;
  `,
  financierBoldN: css`
    font-family: Financier;
    font-weight: 700;
    font-style: normal;
  `,
  financierRegular: css`
    font-family: Financier;
    font-weight: 300;
    font-style: normal;
  `,
  financierRegularM: css`
    font-family: Financier;
    font-weight: 500;
    font-style: normal;
  `,
  financierLight: css`
    font-family: Financier;
    font-weight: 200;
    font-style: normal;
  `,
  financierLightI: css`
    font-family: Financier;
    font-weight: 200;
    font-style: italic;
  `,
  Noto700: css`
    font-family: "Noto Sans KR";
    font-weight: 700;
    font-style: normal;
  `,
  Noto400: css`
    font-family: "Noto Sans KR";
    font-weight: 400;
    font-style: normal;
  `,
}

export const color = {
  whiteMostOpacity: `rgba(255, 255, 255, 0.2)`,
  whiteOpacity: `rgba(255, 255, 255, 0.596)`,
  white: `rgba(255, 255, 255, 1)`,
  greyOpacity: `rgba(206, 210, 211, 0.4)`,
  greyMoreOpacity: `rgba(65, 70, 67, 0.3)`,
  grey: `rgba(206, 210, 211, 1)`,
  greyDarker: `rgba(151, 153, 155, 1)`,
  greyDark: `rgba(65, 70, 67, 1)`,
  greyAbout: `rgba(240, 241, 241, 1)`,
  red: `rgba(225, 6, 0, 1)`,
}

export const ease = {
  out: `cubic-bezier(.17,.67,.83,.67)`,
}

export const device = {
  mobileP: "(min-width: 300px)",
  mobileL: "(min-width: 480px)",
  tabletP: "(min-width: 768px)",
  tabletL: "(min-width: 900px)",
  desktop: "(min-width: 1024px)",
  desktopAnimation: "(min-width: 1024px)",
}
