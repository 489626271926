import styled from "styled-components"
import { color, font, ease, device } from "../../layout/global"

const SidebarWrapper = styled.aside`
  .root {
    position: unset !important;
  }

  .overlay {
    display: none;
  }

  .sidebar {
    position: fixed !important;
    width: 100%;
    height: 100vh;
    background: #e10600;

    @media ${device.mobileP} {
      padding: 0 20px;
    }

    @media ${device.tabletP} {
      padding: 0% 3%;
    }
  }

  .content {
    position: unset !important;
  }

  .wrapper-content {
    margin-top: 125px;
    margin-bottom: 25px;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (max-width: 1023px) {
      margin-top: 75px;
      margin-bottom: 50px;
      height: calc(100% - 125px);
    }
  }

  .pages,
  .second-block,
  .third-block,
  .fourth-block {
    width: fit-content;
    display: flex;
    flex-direction: column;
  }

  .pages * {
    text-transform: capitalize;
    font-size: 5rem;
    line-height: 1.6;
    letter-spacing: 2px;
    color: ${color.white};
    text-decoration: none;

    @media ${device.mobileP} {
      font-size: 3rem;
    }

    @media ${device.desktop} {
      font-size: 5rem;
    }
  }

  .others {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30%;

    @media ${device.mobileL} {
      height: 15%;
    }
  }

  .second-block *,
  .third-block *,
  .fourth-block * {
    text-transform: uppercase;
    text-decoration: none;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 2px !important;
    color: ${color.white};
    ${font.robotoRegular};

    @media ${device.mobileP} {
      font-size: 1rem;
    }
  }

  .pages {
    color: ${color.white};
    margin-top: 20px;

    &:hover a {
      color: ${color.whiteOpacity};
      transition: all 0.3s ${ease.out};
    }

    .active {
      color: ${color.white} !important;
      cursor: pointer;

      animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      @keyframes slide-right {
        0% {
          padding-left: 0;
        }
        100% {
          padding-left: 20px;
        }
      }
    }

    .reverse {
      animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      @keyframes slide-left {
        0% {
          padding-left: 20px;
        }
        100% {
          padding-left: 0;
        }
      }
    }
  }

  .massa-madre {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      margin-top: 5%;

      & svg {
        fill: ${color.white};
        width: 50%;
        max-width: 200px;
        transition: all 0.3s ${ease.out};

        &:hover {
          fill: ${color.grey};
        }
      }
    }
  }
`

export default SidebarWrapper
