import styled from "styled-components"
import { font, color, ease } from "../../layout/global"

// STYLED
const Wrapper = styled.header`
  width: 100%;
  height: 125px;
  z-index: 5;
  position: fixed;
  opacity: 1;
  transition: all 0.3s ${ease.out};

  .lang {
    display: flex;
    margin-left: 2.5rem;

    .nav-link {
      margin-left: 10px;
    }
    .nav-link.active {
      color: ${color.red};
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    margin: 0% 3%;
    height: 100%;
    ${font.robotoMedium};
  }

  .logo-container {
    width: 200px;
    svg,
    svg .cls-1 {
      transition: all ${ease.out} 0.3s !important;
      fill: ${props =>
        props.sidebarLinksColor
          ? `${color.white} !important`
          : props.isDarkened
          ? `${color.greyDark} !important`
          : `${color.white} !important`};
      stroke: none !important;
    }
    z-index: 3;
  }

  .nav-primary {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
  }

  .dropbtn {
    color: ${props => (props.sidebarLinksColor ? color.white : props.isDarkened ? color.greyDark : color.white)};

    svg {
      fill: ${props => (props.sidebarLinksColor ? color.white : props.isDarkened ? color.greyDark : color.white)};
    }
  }

  .nav-secondary {
    display: flex;
    flex-direction: row;
    position: relative;

    .search-container {
      z-index: 3;

      svg {
        height: auto;
        width: 16px;
        transition: all ${ease.out} 0.3s !important;
        fill: ${props => (props.isDarkened ? color.greyDark : color.white)};
      }

      .search-submit {
        display: flex;
        align-items: center;

        .search-submit:hover svg {
          fill: ${props => (props.sidebarLinksColor ? color.white : color.red)};
        }
      }

      .search {
        border: none;
        outline: none;
        background: none;
        border-bottom: 1px solid;
        border-color: ${props =>
          props.sidebarLinksColor ? color.whiteOpacity : props.isDarkened ? color.greyDark : color.whiteOpacity};
        position: absolute;
        right: 90px;
        width: 0px;
        transition: width 0.3s ease-in-out, opacity 0.6s ease-in-out;
        opacity: 0;
        pointer-events: none;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-style: normal;
        color: ${props =>
          props.sidebarLinksColor
            ? color.white
            : props.location === "/assistencia"
            ? color.whiteOpacity
            : props.isDarkened && props.location !== "/assistencia"
            ? color.greyDark
            : color.white};
        letter-spacing: 1.5px;
      }

      .active-input {
        width: 500px;
        opacity: 100%;
        pointer-events: auto;
      }

      .search-list {
        position: absolute;
        width: 500px;
        right: 90px;
        list-style-type: none;
        padding: 0;
        margin-top: 30px;
        transition: all 0.2s ease-in-out;

        li {
          margin: 5px 0;
        }
      }
    }
  }

  .opacity {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .display-none {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .close-search {
    z-index: 3 !important;
    width: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover:before,
    &:hover:after,
    &:hover div {
      background-color: ${color.red};
    }
  }

  .close-search:after,
  .close-search:before,
  .close-search div {
    background-color: ${props =>
      props.sidebarLinksColor
        ? color.white
        : props.location === "/assistencia"
        ? color.whiteOpacity
        : props.isDarkened && props.location !== "/assistencia"
        ? color.greyDark
        : color.white};
    border-radius: 3px;
    content: "";
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: all 0.2s ease-in-out;
  }

  .close-search:before {
    transform: translateY(6px) rotate(135deg);
  }

  .close-search:after {
    transform: translateY(-6px) rotate(-135deg);
  }

  .close-search div {
    transform: scale(0);
  }

  .nav-link {
    z-index: 3;
  }

  .nav-link,
  .nav-secondary {
    display: flex;
    align-items: center;
    margin-left: ${props => (props.br ? `1.5rem` : `2.5rem`)};
    height: 6rem;
    color: ${props =>
      props.sidebarLinksColor
        ? color.whiteOpacity
        : props.location === "/assistencia"
        ? color.whiteOpacity
        : props.isDarkened && props.location !== "/assistencia"
        ? color.greyDark
        : color.white};
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: color ${ease.out} 0.3s;
    opacity: ${props => (!props.isDarkened && props.location === "/assistencia" ? 0 : 1)};
    pointer-events: all;
    opacity: 100%;
    transition: opacity 0.3s ease-out;

    &:hover {
      color: ${props => props.sidebarLinksColor && color.white};
    }
  }

  .nav-primary:hover > .nav-link {
    opacity: 0.5;
  }
  .nav-primary:hover > .nav-link:hover {
    opacity: 1;
  }

  .nav-link-buy {
    color: ${color.red};
    white-space: nowrap;
  }

  .hvr-overline-from-center {
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 51%;
      right: 51%;
      bottom: 12.5px;
      height: 3.5px;
      transition-property: left, right;
      transition-duration: 0.3s;
      transition-timing-function: ${ease.out};
      background: ${props => (props.sidebarLinksColor ? color.white : props.isDarkened ? color.greyDark : color.white)};
    }

    &:hover:before {
      left: 25%;
      right: 25%;
    }
  }
  .hvr-overline-from-center.active {
    &:before {
      left: 25%;
      right: 25%;
    }
  }

  .nav-link-buy.hvr-overline-from-center:before {
    background: ${color.red};
  }

  /* HIDE AND SHOW SCROLL */

  .header-wrapper {
    height: 125px;
  }

  .headroom {
    will-change: transform;
    transition: transform 400ms linear;
    position: relative;
    /*backdrop-filter: blur(3px);*/
  }

  .headroom--pinned {
    transform: translateY(0%);
  }

  .headroom--unpinned {
    transform: ${props => (props.sidebarLinksColor ? "translateY(0%)" : "translateY(-100%)")};
  }

  /* --- */

  /* HAMBURGUER */

  .nav-secondary button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    z-index: 2;

    &:focus {
      outline: 0;
    }

    .hamburguer-icon {
      margin: 1em;
      width: 20px;

      &:hover:before,
      &:hover:after,
      &:hover div {
        background-color: ${props => (props.sidebarLinksColor ? color.white : color.red)};
      }
    }

    .hamburguer-icon:after,
    .hamburguer-icon:before,
    .hamburguer-icon div {
      background-color: ${props =>
        props.sidebarLinksColor
          ? color.white
          : props.location === "/assistencia"
          ? color.whiteOpacity
          : props.isDarkened && props.location !== "/assistencia"
          ? color.greyDark
          : color.white};
      border-radius: 3px;
      content: "";
      display: block;
      height: 2px;
      margin: 4px 0;
      transition: all 0.2s ease-in-out;
    }

    .hamburguer-icon.active:before {
      transform: translateY(6px) rotate(135deg);
    }

    .hamburguer-icon.active:after {
      transform: translateY(-6px) rotate(-135deg);
    }

    .hamburguer-icon.active div {
      transform: scale(0);
    }
  }
  /* --- */
`

export default Wrapper

export const DropDown = styled.div`
  .dropdown-content {
    display: none;
    position: absolute;
    top: 80px;

    &:hover {
      display: block;
    }

    a {
      padding: 0 6px;

      &::before {
        bottom: 5px;
      }

      &:hover {
        &::before {
          left: 5%;
          right: 55%;
        }
      }
    }

    .active::before {
      left: 5%;
      right: 55%;
    }

    .nav-outros {
      height: 4rem;
    }

    &::before {
      left: 0;
      right: 50%;
    }
  }

  .outros {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &::before {
      display: none;
    }

    &:hover ~ .dropdown-content {
      display: block;
    }
  }
`
